


<template>
  <div>
    <postselect @concel="concel"></postselect>
  </div>
</template>
<script>
import postselect from "../components/postselect/index.vue";
import eventbus from "../util/eventbus";
export default {
  components: {
    postselect,
  },
  created() {
    eventbus.$on("config", (e) => {
      localStorage.setItem("gws", e);
      this.$emit("config",e);
    });
  },
  methods:{
    concel(){
      this.$emit("concel");
    }
  }
};
</script>
<style scoped>
</style>
